<template>
  <div :class="['category-cards-container', { 'deals-page': deals_page_layout }]">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="header_section" class="header-container" v-html="header_section" />
    <ScrollableContainer>
      <a
        v-for="(item, index) in mappedCategories"
        :key="`cat${index}`"
        :href="localePath(item.link.href)"
        :style="backgroundColorStyle"
        :class="['category-card flex-col', { 'black-bg': isBlackBg }]"
      >
        <div v-if="item.publicId" class="image-container flex">
          <CloudinaryImage
            :public-id="item.publicId"
            :options="{ width: state.imgWidth, height: state.imgHeight, quality: 'auto' }"
            :alt="item.title || ''"
            :image-classes-override="['image']"
          />
        </div>
        <div class="content-section flex-col">
          <h3 class="category-title h4 ff-text fw-500">
            {{ item.title }}
          </h3>
          <template v-if="item.secondary_seasonal_desc">
            <div class="desc">
              {{ item.secondary_seasonal_desc }}
            </div>
            <CustomButton :style-color="button_style?.style_color" :style-name="button_style?.type" :is-small="true">
              {{ item.link.title }}
            </CustomButton>
          </template>
        </div>
      </a>
    </ScrollableContainer>
  </div>
</template>

<script setup lang="ts">
import { getAssetPublicId } from '~/util/contentstack/csHelpers';
import { useDeviceStore } from '~/store/device';

const localePath = useLocalePath();
const deviceStore = useDeviceStore();

/* eslint-disable vue/prop-name-casing */
const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  categories: {
    type: Array as () => any[],
    default: () => [],
  },
  // Dynamic content
  header_section: {
    type: String,
    default: '',
  },
  // eslint-disable-next-line vue/require-default-prop
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
  },
  deals_page_layout: {
    type: Boolean,
    default: false,
  },
  button_style: {
    type: Object as () => {
      type: string;
      style_color: string;
    },
    default: () => {
      return {
        type: 'primary',
        style_color: 'black',
      };
    },
  },
});

const state = reactive({
  imgWidth: deviceStore.isMobile ? 318 : deviceStore.isDesktop ? 506 : 318,
  imgHeight: deviceStore.isMobile ? 330 : deviceStore.isDesktop ? 363 : 330,
});

const backgroundColorStyle = computed<string | undefined>(() => {
  const color = props.background_color?.color;
  return color ? `background-color: ${color}` : undefined;
});
const isBlackBg = computed<boolean>(() => {
  return props.background_color?.color === '#1a1a1a' || props.background_color?.color === '#000000';
});
const mappedCategories = computed<any[]>(() => {
  return props.categories?.map((cat: any) => {
    const publicId = getAssetPublicId(cat.image);
    return {
      ...cat,
      publicId,
    };
  });
});
</script>

<style lang="scss" scoped>
.category-cards-container {
  display: flex;
  flex-direction: column;

  .header-container {
    text-align: center;
  }
  .content-section {
    width: 100%;
    flex-grow: 1;
  }

  .category-card {
    $card-width: 460px;
    align-items: flex-start;
    width: $card-width;
    border-radius: 4px;
    border: 1px solid transparent;
    color: $color-neutral-cool-900;

    &:hover {
      :deep(.image) {
        transform: scale(1.1);
      }
    }

    .category-title {
      color: $color-neutral-cool-900;
      margin-top: 1rem;
    }

    .image-container {
      overflow: hidden;
      border-radius: 8px;
      width: 100%;
    }
    :deep(.image) {
      transition: transform 0.2s;
      max-width: 500px;
      width: $card-width;
      height: 330px;
      object-fit: cover;
      margin: 0 auto;
    }
    .desc {
      line-height: 1.5;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
      font-size: 0.75rem;
    }
    a:link,
    a:visited {
      text-decoration: none;
      color: $color-neutral-cool-900;
    }
    .btn {
      margin-top: auto;
      width: fit-content;
    }
    &.black-bg {
      color: $color-neutral-white !important;
    }
  }

  @include local-mixins.tablet_and_mobile {
    $card-width: 320px;
    .category-card {
      width: $card-width;
      .category-title {
        margin-top: 1rem;
        font-size: 1.5rem;
      }
      .btn {
        width: auto;
      }
      :deep(.image) {
        width: $card-width;
        height: 330px;
      }
      &:last-child {
        padding-right: 1rem;
        width: 336px; // width + 1rem
      }
    }
    .header-container {
      text-align: left;
    }
  }
  &.deals-page {
    $card-width: 302px;
    .category-card {
      width: $card-width;
    }
    .category-title {
      margin-top: 0;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      text-transform: capitalize;
    }
    .image-container {
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      :deep(.image) {
        height: 132px;
        width: $card-width;
      }
    }
    .content-section {
      padding: 1rem;
      border: 1px solid $color-neutral-cool-100;
      min-height: 150px;
    }
    .btn {
      margin-top: auto;
    }
    @include local-mixins.tablet_and_mobile {
      $card-width: 320px;
      // .scroll-container {
      //   flex-direction: column;
      // }
      .category-card {
        // width: 100%;
        min-width: $card-width;
        flex-direction: row;
        &:last-child {
          padding-right: 16px;
          min-width: 336px; // width + 1rem
        }
        .desc {
          margin-bottom: 0.75rem;
        }
      }
      .image-container {
        width: 25%;
        height: 100%;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        :deep(.image) {
          width: 100%;
          height: auto;
          min-height: 150px;
        }
      }
      .content-section {
        width: 75%;
        height: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .btn {
        width: fit-content;
        font-size: 14px;
      }
    }
  }
}
</style>
